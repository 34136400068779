<template>
  <Invite :code="code" :isProject="true"/>
</template>

<script>
import Invite from "./Invite";
export default {
  data() {
    return {
      code: "",
    };
  },
  created() {
    this.getUrlParams();
  },
  methods: {
    getUrlParams() {
      this.code = this.$route.params.code;
    },
  },
  components: {
    Invite
  },
};
</script>